import { createApp } from 'vue'
import axios from 'axios'
import App from '@/App.vue'
import config from '@/config'
import HoneybadgerVue from '@honeybadger-io/vue'
import '@/index.css'

axios.defaults.withCredentials = true
axios.defaults.baseURL = config.api.capture

const app = createApp(App)

app.use(HoneybadgerVue, config.honeyBadger)

app.mount('#app')
