<template>
  <div class="py-4 px-6">
    <div class="m-0 mb-8 text-center">
      <h1 class="text-lg my-2">{{ siteName }}</h1>
      <h2 class="text-base">{{ siteAddress }}</h2>
    </div>
    <h1 class="text-center text-xl">QR Code Validation</h1>
    <div class="m-0 mt-6">
      <PhotoInput
        name="qrCodePhoto"
        :error-message="
          errorMessages.qrCodePhoto.show
            ? errorMessages.qrCodePhoto.message
            : ''
        "
        @form-changed="saveFormData"
      />
    </div>
    <div class="my-6">
      <TextInput
        name="locationDescription"
        placeholder="QR Code Location"
        :error-message="
          errorMessages.locationDescription.show
            ? errorMessages.locationDescription.message
            : ''
        "
        @form-changed="saveFormData"
      >
        Describe the location of the QR Code
        <br />
        (e.g. front door, next to counter)
      </TextInput>
    </div>
    <div>
      <TextInput
        name="validationPersonName"
        placeholder="Full Name"
        :error-message="
          errorMessages.validationPersonName.show
            ? errorMessages.validationPersonName.message
            : ''
        "
        @form-changed="saveFormData"
      >
        Enter your full name
      </TextInput>
    </div>
    <div class="mt-8">
      <button
        class="bg-verified-blue py-3 rounded text-white w-full"
        type="submit"
        :disabled="isSubmitting"
        @click="submitForm"
      >
        VALIDATE PLATE
      </button>
    </div>
    <LoadingView :is-loading="isSubmitting" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, ref } from 'vue'
  import PhotoInput from '@/components/PhotoInput.vue'
  import TextInput from '@/components/TextInput.vue'
  import { storeFormData } from '@/services/api/storeValidationForm'
  import LoadingView from '@/components/LoadingView.vue'

  interface componentIdentifier {
    locationDescription: string
    qrCodePhoto: string | null
    validationPersonName: string
  }

  export default defineComponent({
    name: 'QrCodeValidationForm',

    components: { LoadingView, PhotoInput, TextInput },

    props: {
      onSite: {
        type: Boolean,
        default: false,
      },

      siteAddress: {
        type: String,
        default: '',
      },

      siteId: {
        type: Number,
        default: 0,
      },

      siteName: {
        type: String,
        default: '',
      },

      userLocation: {
        type: Object,
        default: (userLocation: {
          latitude: number
          longitude: number
          altitude: number | null
          accuracy: number
        }) => {
          return userLocation
        },
      },

      uuid: {
        type: String,
        default: '',
      },
    },

    emits: ['switchComponent'],

    setup(props, { emit }) {
      const formData = {
        locationDescription: '',
        onSite: props.onSite,
        qrCodePhoto: '',
        validationPersonName: '',
      }
      const errorMessages = reactive({
        locationDescription: {
          message: 'Description required',
          show: false,
        },

        qrCodePhoto: {
          message: 'Photo required',
          show: false,
        },

        validationPersonName: {
          message: 'Name required',
          show: false,
        },
      })
      const isSubmitting = ref(false)

      function saveFormData(change: {
        componentIdentifier: keyof componentIdentifier
        value: string
      }) {
        formData[change.componentIdentifier] = change.value
        errorMessages[change.componentIdentifier].show = !change.value
      }

      function validateUserAnswers(): boolean {
        for (const errorMessage of Object.values(errorMessages)) {
          if (errorMessage.show) {
            return false
          }
        }
        return true
      }
      async function submitForm() {
        for (const [key, value] of Object.entries(formData)) {
          if (value === '') {
            errorMessages[key as keyof componentIdentifier].show = true
          }
        }

        if (validateUserAnswers()) {
          isSubmitting.value = true
          const response = await storeFormData(
            props.uuid,
            props.siteId,
            formData.locationDescription,
            formData.qrCodePhoto,
            formData.validationPersonName,
            formData.onSite,
            props.userLocation as {
              latitude: number
              longitude: number
              altitude: number | null
              accuracy: number
            },
          )
          response !== undefined && emit('switchComponent', 'ValidationSuccess')
        }
      }

      return {
        errorMessages,
        isSubmitting,
        saveFormData,
        submitForm,
      }
    },
  })
</script>
