<template>
  <div class="w-full h-screen relative">
    <div class="w-full absolute bottom-1/2 text-center">
      <p class="font-bold mb-24">QR Code not allocated to site</p>
      <p>Verified 1300 133 950</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'QrCodeNotAllocatedToSite',
  })
</script>
