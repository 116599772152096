<template>
  <div class="w-10/12 m-auto text-center">
    <h1 class="text-lg my-2">{{ siteName }}</h1>
    <h2 class="text-base">{{ siteAddress }}</h2>
    <div class="w-16 h-16 m-auto my-6 text-green-600">
      <CheckCircleIcon />
    </div>
    <p>You successfully installed the QR Code at {{ validatedAt }}</p>
    <p class="mt-6 font-bold">Please open camera and rescan plate to test</p>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { CheckCircleIcon } from '@heroicons/vue/outline'
  import dayjs from 'dayjs'
  import advancedFormat from 'dayjs/plugin/advancedFormat'

  export default defineComponent({
    name: 'ValidationSuccess',

    components: {
      CheckCircleIcon,
    },

    props: {
      siteAddress: {
        type: String,
        default: '',
      },

      siteName: {
        type: String,
        default: '',
      },
    },

    setup() {
      dayjs.extend(advancedFormat)
      const validatedAt = ref(dayjs().format('hh:mma on Do MMMM YYYY'))

      return {
        validatedAt,
      }
    },
  })
</script>
