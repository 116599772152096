<template>
  <div
    v-if="isLoading"
    class="fixed bg-white bg-opacity-60 bottom-0 left-0 right-0 top-0 inline-flex items-center justify-center z-20"
  >
    <span class="animate-spin-slow h-12 w-12">
      <img
        id="loadingIcon"
        src="../assets/black-spinner-transparent.png"
        class="w-full h-full"
        alt="loadingIcon"
      />
    </span>

    <span class="text-lg block">{{ message }}</span>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'LoadingView',
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },

      message: {
        type: String,
        default: 'Loading...',
      },
    },
  })
</script>
