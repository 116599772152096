import { instruction } from '@/config'
import threeDotsIcon from '@/assets/three-dots.png'
import chromeIcon from '@/assets/chrome.png'
import safariIcon from '@/assets/safari.jpg'
import settingIcon from '@/assets/setting.png'

const android: instruction = {
  content: [
    'Tap the 3 dots at top right corner',
    'Select Settings',
    'Select Site Settings',
    'Select Location and enable',
    'Go back to Verified login page',
  ],
  contentIcon: threeDotsIcon,
  device: 'android',
  title: 'Open Google Chrome',
  titleIcon: chromeIcon,
}

const ios: instruction = {
  content: [
    'Tap Safari and scroll down',
    'Tap "Location" and tap Allow',
    'Go back to Verified login page',
  ],

  contentIcon: safariIcon,
  device: 'ios',
  title: 'Open iPhone Settings',
  titleIcon: settingIcon,
}

export default {
  android: android,
  ios: ios,
}
