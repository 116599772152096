<template>
  <div class="w-full h-screen relative">
    <div class="w-full absolute bottom-1/2 text-center">
      <div>Please install the QR Code on the correct site location</div>
      <div class="mt-6">Please call Verified on 1300 133 950</div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'CallVerifiedForIncorrectLocation',
  })
</script>
