<template>
  <div class="py-4 px-6 text-center">
    <h1 class="text-lg my-2">{{ siteName }}</h1>
    <h2 class="text-base">{{ siteAddress }}</h2>
    <div class="my-32">
      <p>We have detected you may not be on site</p>
      <p class="mt-4 font-bold">
        Please confirm you are at the location listed above?
      </p>
    </div>
    <div class="grid gap-2 grid-cols-2">
      <div class="col-span-1">
        <button
          type="button"
          class="bg-verified-blue w-full py-3 rounded text-white"
          @click="rebutLocation"
        >
          No
        </button>
      </div>
      <div class="col-span-1">
        <button
          type="button"
          class="bg-verified-blue w-full py-3 rounded text-white"
          @click="confirmLocation"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'NotOnSiteDetectedView',

    props: {
      siteAddress: {
        type: String,
        default: '',
      },

      siteName: {
        type: String,
        default: '',
      },
    },

    emits: ['switchComponent'],

    setup(_, { emit }) {
      function confirmLocation() {
        emit('switchComponent', 'QrCodeValidationForm')
      }

      function rebutLocation() {
        emit('switchComponent', 'CallVerifiedForIncorrectLocation')
      }

      return {
        confirmLocation,
        rebutLocation,
      }
    },
  })
</script>
