import axios from 'axios'
import { addOrAppendAxiosHeader } from '@/services/axios'

const tokenRequestInterceptor = (token: string): void => {
  axios.interceptors.request.use(
    function (config) {
      config.headers = addOrAppendAxiosHeader(
        'x-verified-token',
        `Bearer ${token}`,
        config.headers,
      )

      return Promise.resolve(config)
    },
    function (error) {
      return Promise.reject(error)
    },
  )
}

export default tokenRequestInterceptor
