import { api } from '@/config/api'
import { debug } from '@/config/debug'
import { honeyBadger } from '@/config/honeyBadger'
import { fingerprint } from '@/config/fingerprint'
import { radius } from '@/config/distance'
import type { instruction } from '@/config/help/instructions'

const config = {
  api: {
    capture: api,
  },
  debug,
  fingerprint,
  honeyBadger,
  radius,
}

export type { instruction }

export default config
