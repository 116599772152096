import FingerprintJS, {
  defaultEndpoint,
  defaultScriptUrlPattern,
  ExtendedGetResult,
} from '@fingerprintjs/fingerprintjs-pro'
import config from '@/config'

export const initialiseFingerprint = async (): Promise<ExtendedGetResult> => {
  return FingerprintJS.load({
    apiKey: config.fingerprint.token,
    endpoint: [config.fingerprint.endpoint, defaultEndpoint],
    scriptUrlPattern: [
      config.fingerprint.scriptUrlPattern,
      defaultScriptUrlPattern,
    ],
  })
    .then((fp): Promise<ExtendedGetResult> => fp.get({ extendedResult: true }))
    .then((result) => {
      return result
    })
    .catch(() => {
      throw new Error(
        'Error occurred trying to initialise application. Please try again later.',
      )
    })
}
