<template>
  <div class="mt-1 mb-5" :class="name">
    <span :class="{ 'text-red-500': !!errorMessage }">
      <slot></slot>
    </span>
    <input
      v-model="inputValue"
      :class="{ 'placeholder-red-500': !!errorMessage }"
      :placeholder="placeholder"
      :type="type"
      :minlength="min"
      :maxlength="max"
      :disabled="readonly"
      class="border border-gray-500 disabled:opacity-50 duration-300 focus:ring-2 h-12 outline-none px-2 py-1 rounded-md text-gray-800 w-full"
    />
    <span class="mt-1 mb-1 text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'

  export default defineComponent({
    name: 'TextInput',

    props: {
      // eslint-disable-next-line vue/no-unused-properties
      conditions: {
        type: Array,
        default: () => undefined,
      },

      errorMessage: {
        type: String,
        default: '',
      },

      max: {
        type: Number,
        default: Infinity,
      },

      min: {
        type: Number,
        default: Infinity,
      },

      name: {
        type: String,
        default: '',
      },

      placeholder: {
        type: String,
        default: '',
      },

      readonly: {
        type: Boolean,
        default: false,
      },

      type: {
        type: String,
        default: 'text',
      },
    },

    emits: ['formChanged'],

    setup(props, { emit }) {
      const inputValue = ref('')
      const identifier = ref<string>(props.name)

      watch(inputValue, () => {
        emit('formChanged', {
          componentIdentifier: identifier.value,
          value: inputValue.value,
        })
      })

      return {
        identifier,
        inputValue,
      }
    },
  })
</script>
