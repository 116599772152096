<template>
  <button
    :class="[identifier, buttonClasses]"
    class="disabled:opacity-50 focus:bg-gray-400 p-3 w-full"
    :type="type"
    :disabled="disabled"
    @click="click"
  >
    {{ action }}
  </button>
</template>

<script lang="ts">
  import { ButtonHTMLAttributes, defineComponent, PropType } from 'vue'

  export default defineComponent({
    name: 'BaseButton',

    props: {
      action: {
        type: String,
        default: 'Continue',
      },

      buttonClasses: {
        type: String,
        default: 'bg-blue-500 text-white rounded-sm',
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      identifier: {
        type: String,
        default: '',
      },

      type: {
        type: String as PropType<ButtonHTMLAttributes['type']>,
        default: 'button',
      },
    },

    emits: ['click'],

    methods: {
      click(event: unknown) {
        this.$emit('click', event)
      },
    },
  })
</script>
