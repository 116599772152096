<template>
  <div class="bg-verified-blue p-4">
    <VerifiedLogo height="30" width="150" class="mb-1 mt-1" />
  </div>
  <ErrorView>
    <Suspense timeout="0">
      <template #default>
        <AppController />
      </template>
      <template #fallback>
        <LoadingView :is-loading="true" />
      </template>
    </Suspense>
  </ErrorView>
</template>

<script lang="ts">
  import VerifiedLogo from '@/components/VerifiedLogo.vue'
  import AppController from '@/components/AppController.vue'
  import ErrorView from '@/components/ErrorView.vue'
  import LoadingView from '@/components/LoadingView.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'App',

    components: {
      AppController,
      ErrorView,
      LoadingView,
      VerifiedLogo,
    },
  })
</script>
