<template>
  <div class="qr-code-photo my-4">
    <span class="text-justify" :class="{ 'text-red-500': !!errorMessage }">
      Please take a photo of the QR Code and its surrounds (only 1 photo
      required)
    </span>
    <div class="w-16 m-auto bg-verified-blue rounded mt-4 p-2">
      <label for="qr-code-photo">
        <CameraIcon class="h-8 text-white w-7 m-auto" />
        <input
          id="qr-code-photo"
          type="file"
          accept="image/*"
          class="hidden"
          :disabled="uploadedPhoto !== null"
          @change="receiveAndSavePhoto"
        />
      </label>
    </div>

    <div v-show="!!uploadedPhoto">
      <canvas
        id="qr-code-photo-canvas"
        class="border-2 border-blue-500 border-dashed h-32 inline-block mr-3 mt-5 w-24"
        @touchstart="viewPhoto = true"
      />
    </div>

    <div
      v-if="viewPhoto"
      class="fixed bg-black bottom-0 h-screen left-0 right-0 top-0 z-10"
    >
      <div class="grid grid-cols-2 p-4 pb-20">
        <button type="button" @click="viewPhoto = false">
          <ArrowLeftIcon class="h-6 text-white w-6" />
        </button>
        <button
          type="button"
          class="left-3/4 pl-5 relative"
          @click="removePhoto"
        >
          <TrashIcon class="h-6 text-white w-6" />
        </button>
      </div>
      <div class="flex h-3/5 justify-center w-full">
        <img
          id="photoInspection"
          alt="Sorry, something went wrong"
          class="border-2 border-gray-700 border-solid m-auto object-cover"
          :src="uploadedPhoto?.src"
        />
      </div>
    </div>
    <span class="mt-1 mb-1 text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
  import { defineComponent, nextTick, ref, watch } from 'vue'
  import { ArrowLeftIcon, CameraIcon, TrashIcon } from '@heroicons/vue/outline'
  import loadImage from 'blueimp-load-image'

  export default defineComponent({
    name: 'PhotoInput',

    components: { ArrowLeftIcon, CameraIcon, TrashIcon },
    props: {
      errorMessage: {
        type: String,
        default: '',
      },

      name: {
        type: String,
        default: '',
      },
    },

    emits: ['formChanged'],

    setup(props, { emit }) {
      const viewPhoto = ref(false)
      const uploadedPhoto = ref<HTMLImageElement | null>(null)
      const identifier = ref<string>(props.name)

      watch(
        () => uploadedPhoto.value,
        () => {
          eraseImagesOnCanvas()
          nextTick(() => {
            uploadedPhoto.value !== null &&
              drawImageOnCanvas(uploadedPhoto.value)
            emit('formChanged', {
              componentIdentifier: identifier.value,
              value: uploadedPhoto.value ? uploadedPhoto.value.src : '',
            })
          })
        },
        { deep: true },
      )

      function drawImageOnCanvas(image: HTMLImageElement) {
        const canvas = document.querySelector(
          '#qr-code-photo-canvas',
        ) as HTMLCanvasElement

        const canvasContext = canvas.getContext('2d')
        canvasContext?.drawImage(image, 0, 0, canvas.width, canvas.height)
      }

      function eraseImagesOnCanvas() {
        const canvas = document.querySelector(
          '#qr-code-photo-canvas',
        ) as HTMLCanvasElement
        const canvasContext = canvas.getContext('2d')
        canvasContext?.clearRect(0, 0, canvas.width, canvas.height)
      }

      function storePhoto(rawImage: HTMLCanvasElement) {
        const resizedImage = new Image()
        resizedImage.src = rawImage.toDataURL('image/jpeg', 0.7)
        resizedImage.onload = function () {
          uploadedPhoto.value = resizedImage
          drawImageOnCanvas(uploadedPhoto.value as HTMLImageElement)
        }
      }

      function receiveAndSavePhoto(evt: Event | unknown) {
        const target = (evt as Event).target as HTMLInputElement & EventTarget
        const imageFile = (target.files as FileList)[0]
        const options = {
          canvas: true,
          downsamplingRatio: 1,
          maxWidth: 1024,
          minWidth: 1024,
        }

        if (imageFile) {
          loadImage(imageFile, storePhoto, options)
        }
      }

      function removePhoto() {
        uploadedPhoto.value = null
        viewPhoto.value = false
      }

      return {
        identifier,
        receiveAndSavePhoto,
        removePhoto,
        uploadedPhoto,
        viewPhoto,
      }
    },
  })
</script>
