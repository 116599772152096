<template>
  <div>
    <Component
      :is="currentComponent"
      :site-name="siteName"
      :site-id="siteId"
      :site-address="siteAddress"
      :on-site="onSite"
      :user-location="userLocation"
      :user-device="userDevice"
      :uuid="uuid"
      @switch-component="switchComponent"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import VerifiedLogo from '@/components/VerifiedLogo.vue'
  import QrCodeValidationForm from '@/views/QrCodeValidationForm.vue'
  import ValidationSuccess from '@/views/ValidationSuccess.vue'
  import NotOnSiteDetectedView from '@/views/NotOnSiteDetectedView.vue'
  import CallVerifiedForIncorrectLocation from '@/views/CallVerifiedForIncorrectLocation.vue'
  import QrCodeNotAllocatedToSite from '@/views/QrCodeNotAllocatedToSite.vue'
  import LocationHelp from '@/views/LocationHelp.vue'
  import ErrorView from '@/components/ErrorView.vue'
  import LoadingView from '@/components/LoadingView.vue'
  import { initialiseFingerprint } from '@/services/fingerprint'
  import { getQrCodePlate, QrCodePlate } from '@/services/api/getQrCodePlate'
  import config from '@/config'
  import { tokenRequestInterceptor } from '@/services/api/interceptors'

  export default defineComponent({
    name: 'AppController',

    components: {
      CallVerifiedForIncorrectLocation,
      ErrorView,
      LoadingView,
      LocationHelp,
      NotOnSiteDetectedView,
      QrCodeNotAllocatedToSite,
      QrCodeValidationForm,
      ValidationSuccess,
      VerifiedLogo,
    },

    async setup() {
      const componentsMap = ref({
        CallVerifiedForIncorrectLocation: 'CallVerifiedForIncorrectLocation',
        LocationHelp: 'LocationHelp',
        NotOnSiteDetectedView: 'NotOnSiteDetectedView',
        QrCodeNotAllocatedToSite: 'QrCodeNotAllocatedToSite',
        QrCodeValidationForm: 'QrCodeValidationForm',
        ValidationSuccess: 'ValidationSuccess',
      })
      const currentComponent = ref('')

      const onSite = ref(false)
      const userLocation = ref<{
        accuracy: number
        altitude: number | null
        latitude: number
        longitude: number
      }>({ accuracy: 0, altitude: 0, latitude: 0, longitude: 0 })
      const siteId = ref(0)
      const siteName = ref('')
      const siteAddress = ref('')
      const userDevice = ref('')
      const uuid = ref(
        new URL(window.location.href).searchParams.get('uuid') || '',
      )
      // token comes from index.html and is injected via cloudflare worker
      // eslint-disable-next-line no-undef
      const verifiedToken = token

      userDevice.value = (await initialiseFingerprint()).os
      tokenRequestInterceptor(verifiedToken)
      await acquireUserLocation()
      await determineNextPage()

      // eslint-disable-next-line no-undef
      function setUserLocation(position: GeolocationCoordinates) {
        userLocation.value.latitude = position.latitude
        userLocation.value.longitude = position.longitude
        userLocation.value.altitude = position.altitude
        userLocation.value.accuracy = position.accuracy
      }

      async function acquireUserLocation() {
        return new Promise((resolve) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setUserLocation(position.coords)
              resolve(position.coords)
            },
            () => {
              currentComponent.value = componentsMap.value.LocationHelp
            },
            {
              enableHighAccuracy: true,
              maximumAge: 0,
              timeout: 5000,
            },
          )
        })
      }

      async function determineNextPage() {
        try {
          const plate = await getQrCodePlate(uuid.value, userLocation.value)
          storeSite(plate)

          onSite.value = checkIfOnSite(plate)

          if (onSite.value) {
            currentComponent.value = componentsMap.value.QrCodeValidationForm
          } else {
            currentComponent.value = componentsMap.value.NotOnSiteDetectedView
          }
        } catch {
          currentComponent.value = componentsMap.value.QrCodeNotAllocatedToSite
        }
      }

      function storeSite(plate: QrCodePlate) {
        siteId.value = plate?.site.id ?? 0
        siteName.value = plate?.site.name ?? ''
        siteAddress.value = plate?.site.address ?? ''
      }

      function checkIfOnSite(plate: QrCodePlate): boolean {
        return (
          plate?.userDistanceFromSite !== undefined &&
          config.radius.site >= plate?.userDistanceFromSite
        )
      }

      function switchComponent(name: keyof typeof componentsMap.value) {
        currentComponent.value = componentsMap.value[name]
      }

      return {
        currentComponent,
        onSite,
        siteAddress,
        siteId,
        siteName,
        switchComponent,
        userDevice,
        userLocation,
        uuid,
      }
    },
  })
</script>
