import axios from 'axios'

export const storeFormData = async function (
  uuid: string,
  siteId: number,
  locationDescription: string,
  qrCodePhoto: string,
  validationPersonName: string,
  onSite: boolean,
  userLocation: {
    latitude: number
    longitude: number
    altitude: number | null
    accuracy: number
  },
): Promise<number | undefined> {
  const response = await axios.post(
    '/api/validation/plates',
    {
      description: locationDescription,
      photo: qrCodePhoto,
      siteId: siteId,
      uuid: uuid,
      validator: {
        location: userLocation,
        name: validationPersonName,
        onSite: onSite,
      },
    },
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  )

  if (response.data?.errors) {
    throw new Error(response?.data?.errors?.message)
  }

  return response?.data?.data?.id
}
