import axios from 'axios'

export type QrCodePlate = {
  description: string
  id: number
  site: Site
  userDistanceFromSite: number
  uuid: string
}

type Site = {
  address: string
  customer: Customer
  id: number
  latitude: number
  longitude: number
  name: string
}

type Customer = {
  email: string
  hasAccreditation: boolean
  hasCheckList: boolean
  hasGPS: boolean
  hasInduction: boolean
  hasNotice: boolean
  hasUnregisteredContractor: boolean
  hasWorkOrder: boolean
  id: number
  name: string
  phone: string
  postcode: string
  state: string
  street: string
  town: string
}

export const getQrCodePlate = async function (
  uuid: string,
  userLocation: {
    latitude: number
    longitude: number
    altitude: number | null
    accuracy: number
  },
): Promise<QrCodePlate> {
  const response = await axios.get(`/api/validation/plates/${uuid}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      'location.accuracy': userLocation.accuracy,
      'location.altitude': userLocation.altitude,
      'location.latitude': userLocation.latitude,
      'location.longitude': userLocation.longitude,
    },
  })

  if (response?.data?.errors) {
    throw new Error(response?.data?.errors?.message)
  }

  return response?.data?.data
}
